.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 1.8rem;
    &-container {
        border-bottom: 3px solid #0073aa;
    }
    &-bottom{
        margin: 0rem 0rem 0rem 2rem;
    }
    &-right {
        display: flex;
        // @media only screen and (max-width: 600px) {
        //     display: none;;
        // }
    }
    &-vertical {
        margin: 0rem 0rem 0rem 0rem;
        text-decoration: none;
        // width: fit-content;
    }
    &-horizontal {
        margin: 0rem 0rem 0rem 1rem;
        text-decoration: none;
        @media only screen and (max-width: 600px) {
            display: none;
        }
    }
    &-icon{
        margin: 0rem 1rem 0rem 0rem;
        margin-right: 1rem;
    }
}

.menu {
    display: flex;
    justify-content: space-around;
}
.home-page-logo {
    height: 4rem;
    margin: 0.25rem 0rem 0.25rem 0.25rem;
}

