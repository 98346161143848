.iri-panel {
    height: 24rem;
    // width: 1800px;
    margin: 0rem 18vw 2rem 18vw;
    &-blue {
        height: 28rem;
        width: 100vw;
        padding: 0rem 10rem 0rem 10rem;
        background-color: #d8eefe;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    @media only screen and (max-width: 600px) {
        margin: 0rem 2rem 2rem 2rem;
        height: fit-content;
        &-blue {
            height: fit-content;
            padding: 0rem 2rem 0rem 2rem;
            // margin: 2rem 0rem 2rem 0rem;
            background-color: #d8eefe;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
.iri-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.color-container{
    display: flex;
    justify-content: center;
    background-color: white;
    width: 100%;
    &-blue{
        @extend .color-container;
        background-color: #d8eefe;
        
    }
}

.content-container {
    margin: 2rem 0rem 2rem 0rem;
    width: 1000px;
    @media only screen and (max-width: 1100px) {
        width: 100%;
        margin: 2rem 2rem 2rem 2rem;
    }
    @media only screen and (max-width: 600px) {
        margin: 1rem 2rem 1rem 2rem;

        h1{
            font-size: 24px;
        }
    }

}

.flex-center {
    display: flex;
    justify-content: center;
}

.p-iri {
    text-align: justify;
    font-size: 24px;
    margin: 0rem 3rem 2rem 3rem;
    @media only screen and (max-width: 600px) {
        margin: 0rem 1.5rem 1.5rem 1.5rem;
        font-size: 15px;
    }
}

.iri-header {
    margin: 1rem 0rem 2rem 0rem;
}

.p-container {
    width: 500px;
    text-align: justify;
}

.iri-stock-image {
    margin: 2rem 0rem 2rem 0rem;
    border-radius: 15px;
    object-fit: cover;
    outline-style: solid;
    outline-color: #d8eefe;
    outline-color: #0073aa;
    outline-width: 5px;
    height: 350px;
    width: 90%;
    @media only screen and (max-width: 950px) {
        height: 250px;
        width: 250px;
        font-size: 15px;
    }
    @media only screen and (max-width: 650px) {
        height: 150px;
        width: 150px;
        font-size: 15px;
    }
}

.center-image {
    display: flex;
    justify-content: center;
    // @media only screen and (max-width: 600px) {
    // }
}
