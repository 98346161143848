*,
*::before,
*::after {
    box-sizing: border-box;
}

:root {
    -moz-tab-size: 4;
    tab-size: 4;
}

.page-margins {
    margin: 0rem 4rem 0rem 4rem;
}

h5 {
    margin-top: 7px;
    font-weight: bold;
}

.bottom-bar-container {
    background-color: #424545;
    height: 500px;
}

h1,
h2,
h3,
h4,
h5 {
    color: #0073aa;
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.flex-center {
    &-
    &-space-around {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
}

.flex {
    display: flex;
}

.justify{
    &-center {
        justify-content: center;
    }
    &-space-around {
        justify-content: space-around;
    }
    &-space-between {
        justify-content: space-between;
    }
}

.align {
    &-center {
        align-items: center;
    }

}

.flex-direction{
    &-column{
        flex-direction: column;
    }
}

.invisible {
    display: none;
    &-when-big{
        display: none;
        @media only screen and (max-width: 600px) {
            display: contents;
        };
    }
    &-when-small{
        @media only screen and (max-width: 600px) {
            display: none;
        };

    }
}