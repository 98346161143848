.contact-picture {
    object-fit: cover;
    outline-style: solid;
    outline-color: #0073aa;
    outline-width: 3px;
    border-radius: 20px;
    @media only screen and (max-width: 600px) {
        width: 300px;
        height: 300px;
        border-radius: 150px;
    }
    &-container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        @media only screen and (max-width: 600px) {
            display: none;
        }
    }
}

.vl {
    border-left: 4px solid #0073aa;
    height: 80vh;
    @media only screen and (max-width: 600px) {
        display: none;
    }
}

.contact-callout {
    background-color: #d8eefe;
    background-color: white;
    outline-style: solid;
    outline-color: #0073aa;
    outline-width: 4px;
    border-radius: 15px;
    height: 250px;
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &-item {
        margin-top: 2rem;
    }
    @media only screen and (max-width: 600px) {
        height: 300px;
        width: 300px;
    }
}

.contact-container {
    display: flex;
    justify-content: space-around;
    height: 97vh;
    background-color: #d8eefe;
    padding: 0rem 2rem 0rem 2rem;
    @media only screen and (max-width: 600px) {
        height: fit-content;
        display: block;
        padding: 0rem 0rem 0rem 0rem;
        background-color: #d8eefe;
    }
}
.forty-vw {
    height: 40vw;
    width: 40vw;
}

.thirty-vw {
    width: 30vw;
}

.form-item {
    margin: 1rem 0rem 0rem 0rem;
    width: 15vw;
    label {
        color: #0073aa;
    }
    input {
        width: 15rem;
    }
    h3 {
        width: 40vw;
    }
}

.message {
    width: 40vw;
    @media only screen and (max-width: 600px) {
        width: 90vw;
    }
}

.mobile-padding {
    padding: 0rem 1rem 0rem 1rem;
}
